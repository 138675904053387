<!--
 * @Author: mulingyuer
 * @Date: 2021-09-03 14:20:57
 * @LastEditTime: 2021-09-08 16:51:11
 * @LastEditors: mulingyuer
 * @Description:  课程管理
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\course-manage\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page course-manage">
    <div class="head">
      <div class="head-btn">
        <el-button size="small" type="primary" @click="onAdd">新增</el-button>
      </div>
      <ListFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <div class="content-tabs">
        <list-tabs
          v-model="postData.tab"
          :tabs="tabArr"
          @tab-click="handleTabClick"
        />
      </div>
      <div class="content-btn">
        <el-button size="small" @click="onopenChangeCategoryDialog"
          >编辑分类</el-button
        >
        <el-button size="small" @click="onListing">批量上架</el-button>
        <el-button size="small" @click="onDelist">批量下架</el-button>

        <el-button size="small" type="primary" @click="addThirdPartyCategory"
          >新增标签</el-button
        >
        <el-button size="small" type="primary" @click="removeThirdPartyCategory"
          >删除标签</el-button
        >
        <el-button size="small" @click="onMultipleDelete">删除</el-button>
        <el-button
          v-if="postData.tab === 'deleted'"
          type="success"
          size="small"
          @click="onMultipleReduction"
        >
          还原
        </el-button>
      </div>
      <el-table
        class="thead-light"
        :data="tbody"
        style="width: 100%"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          v-for="th in thead"
          :key="th.prop"
          :prop="th.prop"
          :label="th.label"
          :minWidth="th.minWidth"
          :sortable="th.sort"
          :fixed="th.fixed"
          :show-overflow-tooltip="th.tooltip"
        >
          <template slot-scope="{ row }">
            <!-- 操作 -->
            <template v-if="th.prop === 'operate'">
              <el-button
                type="text"
                size="mini"
                @click="toStudentList(row.id, row.type_text)"
                >学员列表</el-button
              >
              <el-button type="text" size="mini" @click="onEdit(row.id)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="onUpdatePublish(row.id, 1)"
                v-if="!row.publish_status"
                >上架</el-button
              >
              <el-button
                v-if="row.publish_status"
                type="text"
                size="mini"
                @click="onUpdatePublish(row.id, 0)"
                >下架</el-button
              >
              <MoreBtn @command="onMoreCommand">
                <el-dropdown-item :command="{ type: 'commentList', id: row.id }"
                  >评论列表</el-dropdown-item
                >
                <!--                <el-dropdown-item :command="{type:'promote',id:row.id}">推广</el-dropdown-item>-->
                <el-dropdown-item :command="{ type: 'delete', id: row.id }"
                  >删除</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="postData.tab === 'deleted'"
                  :command="{ type: 'reduction', id: row.id }"
                >
                  还原
                </el-dropdown-item>
              </MoreBtn>
            </template>
            <!-- 排序 -->
            <PopoverInputEdit
              v-else-if="th.prop === 'sort'"
              v-model="row.sort"
              input-type="number"
              :validator="validator"
              @confirm="onConfirm(row)"
            />
            <!-- 其他 -->
            <template v-else-if="th.prop === 'publish_status'">
              <ListStatusText
                :text="row[th.prop] ? '已上架' : '待上架'"
                :type="row[th.prop] ? 'success' : 'info'"
              ></ListStatusText>
            </template>
       
            <template v-else>{{ row[th.prop] | placeholder }}</template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="onChangPage"
      />
    </div>
    <!-- 修改分类弹窗 -->
    <ChangeCategoryDialog
      v-model="openChangeCategoryDialog"
      :activeIds="activeIds"
      :complete="getList"
    />
  </div>
</template>

<script>
import ListFilter from '../../components/course-manage/index/Filter.vue'
import Pagination from '@/base/components/Default/Pagination'
import MoreBtn from '@/base/components/Table/MoreBtn'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
import ChangeCategoryDialog from '../../components/course-manage/index/ChangeCategoryDialog.vue'
import ListStatusText from '@/base/components/List/ListStatusText'

import {
  list,
  softDelete,
  handleDel,
  updatePublish,
  updateThirdCategory,
} from '../../api/course-manage/index'
import { putBack, changeSort } from '../../api/course-manage/index'
import { deleteMsgbox, reductionMsgbox } from '@/base/shortcuts/msgbox'
export default {
  data() {
    return {
      loading: false, //加载中
      postData: {
        tab: 'normal',
      },
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      thead: [
        { label: '排序', prop: 'sort', minWidth: 100 },
        { label: '课程名称', prop: 'name', minWidth: 120, tooltip: true },
        { label: '分类', prop: 'category_name', minWidth: 120 },
        { label: '课程来源', prop: 'source_text', minWidth: 120 },
        { label: '课程类型', prop: 'type_text', minWidth: 120 },
        { label: '标签', prop: 'third_party_category', minWidth: 200 },
        { label: '上架状态', prop: 'publish_status', minWidth: 120 },
        { label: '课程数', prop: 'reses_count', minWidth: 120, sort: 'custom' },
        { label: '已学人数', prop: 'learned_count', minWidth: 120 },
        { label: '创建人', prop: 'creator_name', minWidth: 120, tooltip: true },
        {
          label: '创建时间',
          prop: 'create_time',
          minWidth: 180,
          sort: 'custom',
        },
        { label: '显示范围', prop: 'show_type_text', minWidth: 120 },
        { label: '操作', prop: 'operate', minWidth: 300, fixed: 'right' },
      ],
      tbody: [],
      pageData: {},
      activeIds: [], //表格多选选中的id
      openChangeCategoryDialog: false, //是否显示修改分类弹窗
      validator: {
        type: 'integer',
        min: 0,
        message: '最小不能低于0的整数',
        trigger: 'blur',
      },
    }
  },
  methods: {
    // 新增标签
    addThirdPartyCategory() {
      if (!this.activeIds.length) {
        this.$message({
          type: 'info',
          message: '请先选择数据!',
        })
        return
      }
      console.log(this.activeIds, 'this.activeIds')
      this.$prompt('请输入要新增标签的名字', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '多个标签用,分隔：标签1,标签2',
        inputValidator: (value) => {
          if (!value) {
            return '请输入内容'
          }
        },
      })
        .then(({ value }) => {
          if (value) {
            updateThirdCategory({
              id: this.activeIds,
              update_type: 0,
              third_party_category: value,
            })
              .then((res) => {
                console.log(res)
                this.$message({
                  type: 'success',
                  message: res.msg,
                })
                this.onFilter()
              })
              .catch((err) => [console.log(err)])
          }
        })
        .catch(() => {})
    },
    // 移除标签
    removeThirdPartyCategory() {
      if (!this.activeIds.length) {
        this.$message({
          type: 'info',
          message: '请先选择数据!',
        })
        return
      }
      this.$prompt('请输入要删除标签的名字', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '多个标签用,分隔：标签1,标签2',
        inputValidator: (value) => {
          if (!value) {
            return '请输入内容'
          }
        },
      })
        .then(({ value }) => {
          if (value) {
            updateThirdCategory({
              id: this.activeIds,
              update_type: 1,
              third_party_category: value,
            }).then((res) => {
              console.log(res)
              this.$message({
                type: 'success',
                message: res.msg,
              })
              this.onFilter()
            })
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消输入',
          // })
        })
    },
    // 批量上架
    onListing() {
      if (!this.activeIds.length) {
        this.$message.info('请选择数据项!')
        return
      }
      this.onUpdatePublish(this.activeIds, 1)
    },
    // 批量下架
    onDelist() {
      if (!this.activeIds.length) {
        this.$message.info('请选择数据项!')
        return
      }
      this.onUpdatePublish(this.activeIds, 0)
    },
    //获取列表
    getList(pageData) {
      this.loading = true
      return list({
        ...(pageData || this.postData || {}),
        college_teacher_id: this.$route.query.teacher_id,
      })
        .then((res) => {
          const { data } = res
          this.pageData = data
          this.tbody = data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //筛选回调
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 })
      this.getList()
    },
    //tab切换事件
    handleTabClick() {
      //tab name已经v-model绑定到postData
      Object.assign(this.postData, { page: 1 })
      this.getList()
    },
    //排序事件 descending=倒序，ascending=升序,api默认倒序，所以null=descending
    sortChange({ prop, order }) {
      const is_desc = order === 'ascending' ? 0 : 1
      Object.assign(this.postData, { order_by: prop, is_desc })
      this.getList()
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj)
      this.getList()
    },
    //table多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeIds = arr.map((item) => item.id)
      } else {
        this.activeIds = []
      }
    },
    //更多按钮点击事件
    onMoreCommand({ type, id }) {
      switch (type) {
        case 'delete': //删除
          this.onDelete([id])
          break
        case 'reduction': //还原
          this.onReduction([id])
          break
        case 'promote': //推广
          break
        case 'commentList': //评论列表
          break
      }
    },
    //单个删除
    onDelete(ids, multiple = false) {
      let message = `您是否要删除${multiple ? '选中的' : '该条'}数据？`
      if (this.postData.tab === 'deleted') message += '删除后将不可恢复！'
      const api = this.postData.tab === 'deleted' ? handleDel : softDelete

      deleteMsgbox({
        message,
        confirmCallback: () => {
          return new Promise((resolve, reject) => {
            const postData = { ids }
            api(postData)
              .then((res) => {
                const { msg } = res
                this.$message.success(msg)

                this.getList()
                return resolve(true)
              })
              .catch(() => {
                return resolve(true)
              })
          })
        },
      })
    },
    //多选删除
    onMultipleDelete() {
      if (this.activeIds.length <= 0) {
        return this.$message('没有检测到勾选的数据！')
      }
      this.onDelete(this.activeIds, true)
    },
    //单个还原
    onReduction(ids, multiple = false) {
      const message = `是否还原${multiple ? '选中的' : '该条'}数据？`

      reductionMsgbox({
        message,
        confirmCallback: () => {
          return new Promise((resolve, reject) => {
            const postData = { ids }
            putBack(postData)
              .then((res) => {
                const { msg } = res
                this.$message.success(msg)

                this.getList()
                return resolve(true)
              })
              .catch(() => {
                return resolve(true)
              })
          })
        },
      })
    },
    //多个还原
    onMultipleReduction() {
      if (this.activeIds.length <= 0) {
        return this.$message('没有检测到勾选的数据！')
      }
      this.onReduction(this.activeIds, true)
    },
    //编辑分类
    onopenChangeCategoryDialog() {
      if (this.activeIds.length <= 0) {
        return this.$message('没有检测到勾选的数据！')
      }
      this.openChangeCategoryDialog = true
    },
    //修改排序
    onConfirm({ id, sort }) {
      this.loading = true
      const postData = { id, sort }
      changeSort(postData)
        .then((res) => {
          const { msg } = res
          this.$message.success(msg)

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //新增
    onAdd() {
      this.$router.push({ name: 'ClassroomCourseManageAddEdit' })
    },
    //编辑
    onEdit(id) {
      this.$router.push({
        name: 'ClassroomCourseManageAddEdit',
        query: { courseId: id },
      })
    },
    //编辑
    onUpdatePublish(id, is_publish) {
      updatePublish({ id, is_publish }).then((res) => {
        console.log(res)
        this.getList()
      })
    },
    toStudentList(id, type) {
      this.$router.push({
        name: 'ClassroomCourseManageStudents',
        query: { courseId: id, type: type },
      })
    },
  },
  created() {
    this.getList()
  },
  // 调整title
  beforeRouteEnter(to, from, next) {
    if (to.query.teacher_id && to.query.teacher_name) {
      to.meta.title = `${decodeURIComponent(to.query.teacher_name)}的相关课程`
    }

    next()
  },
  components: {
    ListFilter,
    Pagination,
    MoreBtn,
    ChangeCategoryDialog,
    PopoverInputEdit,
    ListStatusText,
  },
}
</script>

<style lang="scss" scoped></style>
