/*
 * @Author: mulingyuer
 * @Date: 2021-08-24 14:40:53
 * @LastEditTime: 2021-10-14 15:19:19
 * @LastEditors: mulingyuer
 * @Description: element-ui msgbox二次封装
 * @FilePath: \agent-admin-vue\src\base\shortcuts\msgbox.js
 * 怎么可能会有bug！！！
 */
import { MessageBox, Message } from "element-ui";

//删除弹窗提示
export function deleteMsgbox({
  title = "消息",
  message = "您是否要删除该条数据？",
  type = "error",
  confirmCallback = () => { },
  cancelCallback = () => { }
}) {
  MessageBox({
    title,
    message,
    type,
    showCancelButton: true,
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    beforeClose: async (action, instance, done) => {
      try {
        if (action === "confirm") {
          instance.confirmButtonLoading = true;
          await confirmCallback();
          done();
          instance.confirmButtonLoading = false;
        } else {
          await cancelCallback();
          done();
        }
      } catch (error) {
        done();
        if (action === "confirm") instance.confirmButtonLoading = false;
        console.error("删除确认弹窗发生错误：", error);
      }
    }
  }).catch(() => { })
};



//还原提示窗
export function reductionMsgbox({
  title = "消息",
  message = "您是否要还原该条数据？",
  type = "success",
  confirmCallback = () => { },
  cancelCallback = () => { }
}) {
  MessageBox({
    title,
    message,
    type,
    showCancelButton: true,
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    beforeClose: async (action, instance, done) => {
      try {
        if (action === "confirm") {
          instance.confirmButtonLoading = true;
          await confirmCallback();
          done();
          instance.confirmButtonLoading = false;
        } else {
          await cancelCallback();
          done();
        }
      } catch (error) {
        done();
        if (action === "confirm") instance.confirmButtonLoading = false;
        console.error("还原确认弹窗发生错误：", error);
      }
    }
  }).catch(() => { })
};


//自定义配置提示
export function msgBox({
  title = "",
  message = "",
  type = "",
  confirmCallback = () => { },
  cancelCallback = () => { }
}) {
  MessageBox({
    title,
    message,
    type,
    showCancelButton: true,
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    beforeClose: async (action, instance, done) => {
      try {
        if (action === "confirm") {
          instance.confirmButtonLoading = true;
          await confirmCallback();
          done();
          instance.confirmButtonLoading = false;
        } else {
          await cancelCallback();
          done();
        }
      } catch (error) {
        done();
        if (action === "confirm") instance.confirmButtonLoading = false;
        console.error("确认弹窗发生错误：", error);
      }
    }
  }).catch(() => { })
}